// content components
import FAQs from "./FAQs";

function StarterPack() {
    const ex_cover_letter = process.env.PUBLIC_URL + '/consumer-resources/Cover_Letter_Template.pdf';

    return (
        <div className="StarterPack" id="StarterPack">
            <h2>STARTER PACK</h2> {/* centred text large*/}
            <p>Ready to apply? Scroll to get started&nbsp;&#128077;</p>
            <div className="pack-link-container">
                <a href="https://www.youtube.com/watch?v=VDX79EqRjxs&t">
                    <button>Application Tutorial</button>
                </a>
                <a id="job-profiles" href="https://www.prospects.ac.uk/job-profiles/browse-a-to-z">
                    <button>Job Profiles</button>
                </a>
                <a id="cv-cover-letter" href="https://www.prospects.ac.uk/careers-advice/cvs-and-cover-letters/example-cvs/skills-based-cv-example">
                    <button>Template CV</button>
                </a>
                <a href={ ex_cover_letter } download="Software_Degree_Apprenticeship_Example.pdf">
                    <button>Template Cover Letter</button>
                </a>
                <a id="aptitude-tests" href="https://www.assessmentday.co.uk/">
                    <button>Aptitude Tests</button>
                </a>
                <a href="https://www.gradcracker.com/search/all-disciplines/degree-apprenticeships">
                    <button>Gradcracker Jobs</button>
                </a>
                <a href="https://uk.indeed.com/jobs?q=degree+apprenticeship&l=&from=searchOnHP&vjk=77847139a79eaf8e">
                    <button>Indeed Jobs</button>
                </a>
                <a href="https://www.spareroom.co.uk/">
                    <button>Accommodation</button>
                </a>
                <a id="insight-events" href="https://www.gradcracker.com/search/all-disciplines/engineering-work-placements-internships?duration=Insight%20Events">
                    <button>Insight Events</button>
                </a>
                <a id="FAQs-link" href="#FAQs">
                    <button>FAQs</button>
                </a>
            </div>
        </div>
    )
}


function WhatYouDo() {
    return (
        <div className="row align-items-center" id="WhatYouDo">
            <div className="col-sm-3">
                <h3>WHAT DEGREE APPRENTICES ACTUALLY DO?</h3>
            </div>                
            <div className="col-sm-4">
                <div>
                    <h6><strong>OVERALL</strong></h6>
                    <p>
                    Imagine you're doing a Digital Technology Solutions (DTS) Degree Apprenticeship at Amazon while studying part-time at the University of Oxford.
                    Over four years, you'll work continuously at Amazon and attend university block weeks (9-4pm, Mon-Fri) every 5-7 weeks, 
                    except in the summer (no breaks for you!).
                    You'll be in the office 2-3 days a week (this varies by department), and university weeks alternate between in-person and online.
                    <br></br><br></br>
                    It's a great opportunity, with a 90%+ chance of landing a full time role if you nail it, <strong>but no guarantees!</strong>
                    </p>
                </div>
                <div>
                    <h6><strong>IN THE OFFICE</strong></h6>
                    <p>
                        So, at work, you work. Most apprentices have it straightforward, but for me, it's a bit more complex.
                        Hence being pro-active and self-starting is key. Your course modules may not match your department perfectly.
                        For instance, I learned Python and C++ at uni, but my department mainly uses Java.
                        Fortunately, OOP concepts and some data types are consistent across languages.
                        Plus, I had to pick up Google Cloud Platform (GCP) and GitLab CI/CD in my first year, you guessed it - also not covered in uni modules.
                        <br></br><br></br>
                        My work allows 6-month placements in different teams, either within my home department or elsewhere.
                        They are internally reviewed with my manager and an apprentice lead - I present the projects I've completed in that time.
                        These placements are designed to target gaps in Knowledge, Skills, and Behaviors (KSBs), and I log daily activities in a spreadsheet to 
                        track my progress and prep for presentations.
                        Myself and other apprentices in the department also meet bi-weekly with the apprentice lead.
                        One person presents their work so everyone gets a chance to see what else goes on in the function/company.
                        Don't forget all the agile ceremonies, bi-weekly 1-2-1s with your manager, and mentor sessions.
                        <br></br><br></br>
                        It can be demanding, but it's all part of the journey!
                    </p>
                </div>
            </div>
            <div className="col-sm-4">
                <div>
                    <h6><strong>AT UNIVERSITY</strong></h6>
                    <p>
                        I know you care more about real-world experience (otherwise you wouldn't be considering a degree apprenticeship), 
                        but don't sleep on the university side of things.
                        <br></br><br></br>
                        You'll dive into 1-2 modules per block week, every 5-7 weeks.
                        Expect to cover programming (C++, Python, Java etc.), Information Systems, Network Computing, and Business Processes.
                        Don't stress TOO much about the math — this isn't a comp sci degree; it's more like applied software engineering.
                        You'll also tackle topics like Machine Learning, Real Time Operating Systems, Software Development Lifecycle, CI/CD, Web/Mobile Development, etc.
                        During and after each block week, you'll have assignments to complete in your own time, ranging from coursework essays (~2500 words),
                        solution design/implementation (like building a web-app), presentations, or exams.
                        These can be solo or group projects.
                        <br></br><br></br>
                        Over the 4 years, you need to demonstrate a range of KSBs.
                        Think of these as essential skills like "Writing quality code that follows syntax guidelines & industry best practices."
                        You prove this through module learning, an End Point Assessment (EPA) case study, and testimonials from senior colleagues.
                        The EPA involves an interview and case studies backed by witness testimonies from your manager or mentor.
                        Plus, you'll log daily activities related to KSBs using an app called OneFile. It sounds easy, but trust me, it's a grind.
                        This logging helps during tripartite meetings every 3 months with your uni tutor and line manager, 
                        where you discuss any issues and scorecards, ranking yourself from 1-10 on each KSB.
                    </p>
                </div>
                <div>
                    <h6><strong>CLOSING OFF</strong></h6>
                    <p>
                        Being real — we all want to live up soft life. For some, a Degree Apprenticeship will be just that.
                        For others, it will be challenging.
                        <br></br><br></br>
                        Consider this your guide to compare it with the typical uni experience.
                        When I went to university, I partied and played football, probably more than I should've — and still got a degree.
                        I could do that again... but as an apprentice, I can't be complacent.
                        Real professionals rely on me, and there are real goals to achieve.
                        If you want opportunities to gain significant work experience and can handle the admin, graft, and sacrifice,
                        then a Degree Apprenticeship is for you.
                        If your industry doesn't offer them, or you want time to figure things out or party a bit, <strong>look elsewhere!</strong>
                        <br></br><br></br>
                        Thanks for reading my spiel. Good luck!
                    </p>
                </div>
            </div>
        </div>
    )
}


function Why() {
    return (
        <div className="Why">
            <h6><strong>WHY I MADE THIS SITE?</strong></h6>
            <p>
                Firstly, I want to inspire people to be better versions of themselves.
                Part of doing that is by providing the best and most accurate information.<br></br>
                Social media may sometimes obscure reality, so I want to give you clarity on
                how degree apprenticeships go, <br></br>
                whilst creating a platform for support, relatable content, and networking.
                <br></br><br></br>
                ...also this is my 1st React App!&#128187;&#128640;{/* laptop and rocket emoji */}
            </p>
        </div>
    )
}



// final content component comprising components from above
function Content() {
    const up_arrow = process.env.PUBLIC_URL + '/static/images/up-arrow.png'
    
    return (
        <div className="content-container">
            <StarterPack />
            <WhatYouDo />
            <FAQs />
            <Why />
            <a id="up-arrow" href="#main-nav"> {/* may change this after more content*/}
                <img src={up_arrow} alt="degree-apprentice-button" />
            </a>
        </div>
    )
}

export default Content