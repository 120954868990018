// Desc: FAQs component
import React, {useState} from "react";


function FAQs() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleFAQClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    }

    return (
        <div className="FAQs" id="FAQs">
            <h2 className="FAQ-title" >FAQs</h2>
            <div className="FAQ-questions-container">
                <div key="1" className="FAQ-qa-container" onClick={() => handleFAQClick(1)}>
                    <p className="FAQ-question">How to stand out?</p>
                    {activeIndex === 1 
                    && <p className="FAQ-answer">
                            This is a question I get a lot, and firstly I try to make it clear that standing out in competitive environments
                            in general isn't based on technical capability.
                            "Standing out" is about thinking differently and bringing something that no one else can.&nbsp;
                            <strong>The easiest way to do that is by being yourself</strong>&nbsp;
                            <br></br><br></br>
                            I appreciate that may not be a specific enough answer, so here are ways of standing out that work:
                            <ul>
                                <li>&#9989;&nbsp;<strong>Be specific</strong>- Avoid generic comments about being "passionate" and mention specifics about the industry & company</li>
                                <li>&#9989;&nbsp;<strong>Bring a unique perspective </strong>- Research an industry/company topic and briefly discuss this with your hiring manager</li>
                                <li>&#9989;&nbsp;<strong>Showcase emotional intelligence </strong>- Understand and up-lift your fellow interviewees, they are also your potential colleagues</li>
                                <li>&#9989;&nbsp;<strong>Your unique journey </strong>- Briefly discuss your unqiue background and reasoning for choosing the industry and company</li>
                                <li>&#9989;&nbsp;<strong>Relate with the hiring manager </strong>- Sell your story in a way that links you with company principles and the hiring manager</li>
                            </ul>
                            This is not an exhaustive list, you can <strong>stand out</strong> even more with your own ideas&#128077;
                        </p>} 
                </div>
                <div key="2" className="FAQ-qa-container" onClick={() => handleFAQClick(2)}>
                    <p className="FAQ-question">Interview/Assessment Centre tips?</p>
                    {activeIndex === 2 
                    && <p className="FAQ-answer">
                            I personally believe this is the nicest part, because you have been picked over <strong>100-1000s</strong> of other
                            applicants, so all you need to do is be confidently yourself and match the application they
                            based their selection on.
                            <br></br><br></br>
                            This should be straightforward, but here are some tips which may help you in your approach:
                            <ul>
                                <li>&#9989;&nbsp;View it as you testing them to confirm that the company and hiring manager are right for you</li>
                                <li>&#9989;&nbsp;View other applicants as potential colleagues & build rapport - <strong>Think team NOT competition</strong></li>
                                <li>&#9989;&nbsp;Try not to over prepare or focus on too many different things - <strong>Quality over Quantity</strong></li>
                                <li>&#9989;&nbsp;Be punctual and communicate any concerns with recruitement</li>
                                <li>&#9989;&nbsp;Take advantage of any benefits offered - company tours, insight days, travel expensing, coffee etc.</li>
                            </ul>
                            I have heard of a few bad experiences people have had, but for my interview 7 out of 8 applicants who attended were offered roles.
                            We all had a good time and I am even good friends with an apprentice from the same interview today.
                            No one has to lose in order for you to win, but if you can help everyone else win along your way - 
                            I promise it will leave a great feeling long-term!&#128522;
                        </p>}
                </div>
                <div key="3" className="FAQ-qa-container" onClick={() => handleFAQClick(3)}>
                    <p className="FAQ-question">Are certifications or personal projects better?</p>
                    {activeIndex === 3
                    && <p className="FAQ-answer">
                            Both are impressive, but unless the certification is comprehensive,
                            I feel that personal projects provide more practical knowledge to you and evidence of capability to an employer.
                            <br></br><br></br>
                            We've all heard of the "Degree but NO XP" problem, certifications may sometimes fall into this category.
                            Personal projects, on the other hand, require you to have - an inquisitive interest in the industry,
                            willingness to learn the wider context of problems,
                            and confidence to find solutions outside of a structured environment.
                            Which is what the <strong>"Real World"</strong> of employement is like - and is funnily enough where
                            you have to work.
                            <br></br><br></br>
                            An example, is this very amazing website (you said it, not me!&#128540;).
                            I could've easily completed a certification in React, which would've been perfectly fine, but in
                            building this from scratch with <strong>NO</strong> XP of building websites, I learned to employ the following concepts & tools:
                            <ul>
                                <li><u>Concepts</u></li>
                                <li>&#9989;&nbsp;<strong>Technology Analysis</strong> - Assessing, selecting, and reviewing technologies based on project requirements</li>
                                <li>&#9989;&nbsp;<strong>Web Hosting</strong> - Not exhaustively comprising of, acquiring a domain name and hosting the service on a linked cloud server</li>
                                <li>&#9989;&nbsp;<strong>Virtualisation & Containerisation</strong> - Virtual management of hardware resources and packaging of software resources into ready-to-run "containers"</li> 
                                <li>&#9989;&nbsp;<strong>Code Analysis</strong> - Reviewing code for quality, security, and performance</li>
                                <li><u>Tools</u></li>
                                <li>&#9989;&nbsp;<strong>React</strong> - JS library which promotes the use of reusable components whilst building UI</li>
                                <li>&#9989;&nbsp;<strong>Node & npm</strong> - JS runtime environment and package manager</li>
                                <li>&#9989;&nbsp;<strong>Docker</strong> - Platform for developing, shipping, and running applications in virtualised environments</li>
                                <li>&#9989;&nbsp;<strong>Linux Ubuntu</strong> - OS based on Linux Kernel</li>
                                <li>&#9989;&nbsp;<strong>Nginx</strong> - Web server that can also be used as a reverse proxy, load balancer, mail proxy, and HTTP cache</li>

                            </ul>
                            Not only did I gain experience with a variety software engineering concepts and tools. I gained the confidence to build other apps
                            into a <a href="https://www.illack.co.uk">Portfolio</a>&nbsp;of working products that showcases my creativity, problem-solving, and technical skills
                        </p>}
                </div>
                <div key="4" className="FAQ-qa-container" onClick={() => handleFAQClick(4)}>
                    <p className="FAQ-question">What level of responsibility do you have on projects as an apprentice?</p>
                    {activeIndex === 4 
                    && <p className="FAQ-answer">
                            Your responsibility varies as you progress and depends on the team/company you're in and your ability to take on tasks independently.
                            You are largely seen as an individual who will be learning on the job, executing small tasks and shadowing more senior members 
                            of the team at the beginning. As you progress, you will be given more responsibility and autonomy to work on projects independently,
                            ultimately leading a small project end-to-end
                            <br></br><br></br>
                            In my experience, I was given licence to work on projects as independently as I liked from the start, which allowed me to learn quickly 
                            and develop my skills. Provided the freedom to make decisions and take ownership of my work, helped me to grow faster.
                            It's important to communicate with your team/manager about the level of responsibility you're comfortable taking on in each scenario,
                            as your expertise on a topic will vary and so will how much time you have available due to University commitments&nbsp;&#127891;&#128218;
                            <br></br><br></br>
                            Nevertheless, you should be provided with guidance and support as needed, to help you grow in your role!
                        </p>}
                </div>
                <div key="5" className="FAQ-qa-container" onClick={() => handleFAQClick(5)}>
                    <p className="FAQ-question">What type of work experience should you have and do you need to have any?</p>
                    {activeIndex === 5 && <p className="FAQ-answer">
                            Work experience isn't important when applying for a Degree Apprenticeship, but it can only be benificial.
                            <br></br><br></br>
                            If you're applying for a role that relies on a specific skill set, having experience with 
                            that skill set i.e. coding or software development, whether commercial or non-commercial is obviously preferred.
                            If you don't have any industry specific work experience, this is fine as applicants for Degree Apprenticeships
                            are usually expected to be of school leaver age and may not have had the opportunity to gain significant
                            enough work experience. You can still demonstrate your interest in the industry and capability in other ways.
                            Mentioning projects you've worked on, research you've done, clubs/societies you're part of,
                            or other relevant activities, can just as strongly support your application. Do note that too much experience
                            or a degree in a directly related field will make you overqualified for the role and not eligible to apply.
                            Leaving university to apply for a Degree Apprenticeship is perfectly fine, but you should have a strong reason for doing so
                            <br></br><br></br>
                            If you're set on wanting experience, you can find <a href="#insight-events">insight events</a> or work experience
                            opportunities by simply&nbsp;
                            <a href="https://www.google.com/search?q=insight+events&oq=insight+events&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQLhhA0gEIMjA1OGowajCoAgCwAgA&sourceid=chrome&ie=UTF-8">
                                googling "insight events"
                            </a> or contacting companies directly&#128242;&#128229;
                        </p>} 
                </div>
                <div key="6" className="FAQ-qa-container" onClick={() => handleFAQClick(6)}>
                    <p className="FAQ-question">What's the financial breakdown?</p>
                    {activeIndex === 6
                    && <p className="FAQ-answer">
                            Your company pays for your tuition fees through government funded means, and you receive a salary for the work you do.
                            So no student loan "debt" to worry about, but you do have to pay income tax and national insurance as a full time employee
                            earning over the minimum taxable income threshold (£12,750 as of 2025).
                            Your salary increases incrementally each year, and depends on your performance academically and largely on the company you're in
                            <br></br><br></br>
                            As an example, you may expect to earn:
                            <ul>
                                <li>&#128183;&nbsp;<strong>Year 1</strong> - £23,000</li>
                                <li>&#128183;&nbsp;<strong>Year 2</strong> - £26,000</li>
                                <li>&#128183;&nbsp;<strong>Year 3</strong> - £29,000</li>
                                <li>&#128183;&nbsp;<strong>Year 4</strong> - £32,000</li>
                            </ul>
                        </p>} 
                </div>
                <div key="7" className="FAQ-qa-container" onClick={() => handleFAQClick(7)}>
                    <p className="FAQ-question">What's the difference between a Degree Apprenticeship and a Degree?</p>
                    {activeIndex === 7
                    && <p className="FAQ-answer">
                            Having done both, I can say the main difference is that a Degree Apprenticeship demands a higher rate of learning and more
                            responsibility as you work on real projects
                            <br></br><br></br>
                            In a Degree Apprenticeship, you are learning on the job and applying what you learn at University in real time.
                            You are expected to deliver on projects and tasks in a real-world environment, whilst studying where you can
                            to ensure you have a strong degree classification at the end of the programme. University is more theoretical
                            and you have a lot more time to embed learning and apply it in a simpler controlled environment. This isn't me
                            saying University is easy, it's just a different type of learning. But this should be expected as
                            a Degree Apprenticeship is everything university is and more, but with a salary and a job at the end of it!
                            <br></br><br></br>
                            The choice between the two is personal and depends on what you want to get from your learning experience.
                            If you want to learn in a more controlled environment and have more time to socialise or focus your time
                            outside of work on other things, University is a great choice. If you want to learn quickly,
                            apply what you learn in real time, and get paid for it, a Degree Apprenticeship is the way to go!&#129309;
                            <br></br><br></br>
                            Understand this in more depth, read <a href="#WhatYouDo">what degree apprentices actually do?</a>
                        </p>} 
                </div>
                <div key="8" className="FAQ-qa-container" onClick={() => handleFAQClick(8)}>
                    <p className="FAQ-question">How huge is the transition from A-levels to Degree Apprenticeship?</p>
                    {activeIndex === 8
                    && <p className="FAQ-answer">
                            <strong>HUGE</strong> for some. Not so huge for others
                            <br></br><br></br>
                            For my cohort in general, it's been fine. It's all about managing time between working and studying.
                            If you are generally disorganised and struggle to communicate/manage your time and workload in both areas,
                            you may find it difficult. The level of work isn't too difficult until 3rd/4th year, 
                            but the volume of work can be overwhelming as it's delivered in short weekly bursts 
                            (It's more spread out for some newer programmes).
                            Additionally, there are a lot of meetings, reviews, and ceremonies to attend at work, so that adds to the lack of time too 
                            <br></br><br></br>
                            Be organised. Communicate/Manage expectations clearly. Be proactive. And You'll be fine!&#128077;
                            
                        </p>} 
                </div>
                <div key="9" className="FAQ-qa-container" onClick={() => handleFAQClick(9)}>
                    <p className="FAQ-question">When do you usually apply for Degree Apprenticeships?</p>
                    {activeIndex === 9
                    && <p className="FAQ-answer">
                            Applications for Degree Apprenticeships start at the end of the previous year in some cases (i.e. 2027)
                            or Jan-March in the current year (i.e. 2028) in other cases, with the interview process usually lasting
                            a few weeks/months post March, hence apprentices being ready to start in Sep-October of that same year
                        </p>} 
                </div>
                <div key="10" className="FAQ-qa-container" onClick={() => handleFAQClick(10)}>
                    <p className="FAQ-question">How to prepare for the Degree Apprenticeship?</p>
                    {activeIndex === 10
                    && <p className="FAQ-answer">
                            Having a clear mind about what you're signing up for or have signed up to is a good start, so well done
                            for being on this page. In no order, the following advice is provided based on the scenario you may be asking 
                            this question in
                            <br></br><br></br>
                            <u>Preparing to apply:</u><br></br>
                            <ul>
                                <li>&#9989;&nbsp;Ensure you have a clear understanding on the role, company, and industry you're aiming to join</li>
                                <li>&#9989;&nbsp;Practice <a href="#aptitude-tests">Aptitude Tests</a> prior to online/in-person assessments</li>
                                <li>&#9989;&nbsp;Execute/research a specific industry project/topic which genuinely interests you</li>
                                <li>&#9989;&nbsp;Reach out to previous & current apprentices in your desired role for advice</li>
                                <li>&#9989;&nbsp;Find a company that specialises in work matching your desired projects/interests and has a culture matching what you expect</li>
                                <li>&#9989;&nbsp;Create a concise <a href="#cv-cover-letter">CV & Cover Letter</a> specific to the company & role <a href="#job-profiles">job profile</a></li>
                            </ul>
                            <u>Preparing to start:</u><br></br>
                            <ul>
                                <li>&#9989;&nbsp;Email/Message your "to be" manager asking for work/advice to prepare for your new role</li>
                                <li>&#9989;&nbsp;Upskill in the companies/teams chosen tools so it's easier for you to get started</li>
                                <li>&#9989;&nbsp;Network with other hired apprentices to get help on things you may not have thought off</li>
                                <li>&#9989;&nbsp;Contact your companies Early Careers team for advice on other pre-joining queries</li>
                            </ul>
                        </p>} 
                </div>
                <div key="11" className="FAQ-qa-container" onClick={() => handleFAQClick(11)}>
                    <p className="FAQ-question">What Visa requirements do I need to be eligible to apply?</p>
                    {activeIndex === 11 
                    && <p className="FAQ-answer">
                            Apprenticeship programmes in the UK are conventionally government-funded and to qualify for funding, 
                            you must have been a <strong>UK resident for at least three years </strong> prior to starting the apprenticeship.
                            If you don't meet this residency requirement, you're unlikely to be eligible.
                            For apprenticeships, companies generally do not offer salaries high enough to qualify for the Skilled
                            or Health and Care Worker Visa, and do not sponsor work Visas.
                            There are exceptions for - Asylum Seekers, Children of Diplomats, and other Groups (i.e. Indefinite Leave to Remain Holders etc.)
                            <br></br><br></br>
                            If your desire is to find a role with Visa sponsorship, focus on applying for graduate/junior/mid-senior roles
                        </p>} 
                </div>
                <div key="12" className="FAQ-qa-container" onClick={() => handleFAQClick(12)}>
                    <p className="FAQ-question">Do companies provide support with relocation?</p>
                    {activeIndex === 12
                    && <p className="FAQ-answer">
                        I haven't seen evidence of companies providing accommodation, but companies can support you by connecting you with other apprentices
                        who are also looking for accommodation and by providing information on where to find accommodation. You can read more about this through&nbsp;
                        <a href="https://www.ucas.com/money-and-student-life/student-life/accommodation-advice/accommodation-apprentices#employer-support">UCAS</a>
                        <br></br><br></br>
                        For how to find accommodation, see the next question or checkout my <a href="#StarterPack">Starter Pack</a>
                        </p>} 
                </div>
                <div key="13" className="FAQ-qa-container" onClick={() => handleFAQClick(13)}>
                    <p className="FAQ-question">How do I find Accommodation?</p>
                    {activeIndex === 13 && <p className="FAQ-answer">
                            You can find private or shared accommodation through sites like <a href="https://rightmove.co.uk">Rightmove</a> or&nbsp;
                            <a href="https://www.spareroom.co.uk/">SpareRoom</a>&nbsp;&#127968;
                            <br></br><br></br>
                            Private accommodation may be costly, so if you're relying on
                            your salary solely, I'd recommend shared accommodation as it is more affordable. Shared accommodation prices are in the region of 
                            £400-1000/mnth (incl. bills) and depend on location. As a part-time student, you are not exempt from Council Tax&#10071;
                        </p>} 
                </div>
                <div key="14" className="FAQ-qa-container" onClick={() => handleFAQClick(14)}>
                    <p className="FAQ-question">Can you apply without having studied relevant subjects?</p>
                    {activeIndex === 14
                    && <p className="FAQ-answer">
                            As you probably know, companies outline the subjects they require you to have studied in their job descriptions,
                            and it's important to try to meet these requirements. However, if you have a strong interest in the industry and
                            have studied few/none of the required subjects,  you can still be successful in applying by demonstrating your interest and capability 
                            in other ways
                            <br></br><br></br>
                            I studied Maths, Physics, Chemistry, and Business at A-level, but had near to NO knowledge of "background" computing or programming.
                            I had completed a PostgresSQL course on Udemy, and read lightly about blockchain technology and the semi-conductor
                            shortage at the time, but that's it.&nbsp;<strong>Not one print("Hello World")</strong>.
                            On top, I chose to present a house renovation project to my now manager, that required no coding or software skills on my part at all.
                            In fact, I didn't know anything about house renovations either, but I was able to present the project in a way that showed
                            I had the ability to learn and deliver quickly. I also spoke about my thoughts on blockchain technology and the semi-conductor
                            shortage to another hiring manager in part 2 of my assessment centre. It sounds like madness thinking back,
                            but <strong>IT WORKED</strong>!
                            <br></br><br></br>
                            I guess the moral of the story is. If you haven't got the required subjects at all. Research a topic/topics of interest and
                            discuss them with hiring managers to show you have a genuine interest in the industry, and think of a creative way of
                            selling a true story in your application and interview that shows you have an ability to learn and deliver <strong>QUICKLY</strong>.
                            Then, it's possible to apply and succeed!&nbsp;&#127881;
                        </p>} 
                </div>
                <div key="15" className="FAQ-qa-container" onClick={() => handleFAQClick(15)}>
                    <p className="FAQ-question">Do I offer 1:1 sessions?</p>
                    {activeIndex === 15 
                    && <p className="FAQ-answer">
                            Unfortunately not at the moment, but I'm always happy to respond to messages on LinkedIn or comments on my Youtube channel&nbsp;&#128522;
                        </p>} 
                </div>  
            </div>
        </div>
    )
}

export default FAQs;